import { render, staticRenderFns } from "./StockDispensing.vue?vue&type=template&id=0c22a0f8&scoped=true&lang=html&"
import script from "./StockDispensing.vue?vue&type=script&lang=js&"
export * from "./StockDispensing.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c22a0f8",
  null
  
)

export default component.exports