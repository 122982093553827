<template lang="html">
  <v-container pa-0 fluid>
    <v-row v-if="currentStockId" dense>
      <v-col cols="12">
        <v-card color="white">
          <v-card-text class="pa-0">
            <v-container py-2 fluid>
              <v-row dense>
                <v-col cols="11" align-self="center" class="text-left">
                  <span class="display-1">
                    <v-label-api :api-url="['models','Stock','Stocks','find']" :api-key="currentStockId" api-field="stockName"></v-label-api>
                    <template v-if="currentStocksRequest">
                      <v-icon>arrow_forward</v-icon>
                      <v-label-api :api-url="['models','Stock','Stocks','find']" :api-key="currentStocksRequest.stockTo" api-field="stockName"></v-label-api>
                    </template>
                  </span>
                </v-col>
                <v-col cols="1" align-self="center" class="text-right" v-if="currentStocksRequest">
                  <v-btn icon @click="currentStocksRequest=undefined" x-large><v-icon>close</v-icon></v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card> 
      </v-col>
      <v-col cols="12" v-if="currentStocksRequest">
        <v-container fluid>
          <v-row no-gutters align="center">
            <v-col>
              <span class="text-h6">วันที่ต้องการของ {{ currentStocksRequest.requestDispensingDate }}</span>
            </v-col>
            <v-col class="text-right">
              <v-btn class="mr-1" @click="closeReject" color="error">Reject</v-btn>
              <v-btn class="mr-1 white--text" @click="closeComplete" color="green darken-2">Complete</v-btn>
            </v-col>
          </v-row>
        </v-container>
        <v-tabs-group>
          <template v-slot:tabs>
            <v-tab key="request">ใบเบิกของ</v-tab>
            <v-tab key="dispensings">จ่ายของ</v-tab>
          </template>
          <template v-slot:items>
            <v-tab-item key="request">
              <v-card>
                <v-card-text>
                  <v-container fluid>
                    <v-row>
                      <v-col cols="4">
                        <v-date-field v-model="currentStocksRequest.requestDispensingDate" label="วันที่ต้องการของ" readonly></v-date-field>
                      </v-col>
                      <v-col cols="4">
                        <v-master-field  v-model="currentStocksRequest.stockFrom" 
                          :custom-api="['models','Stock','Stocks','query']"
                          custom-api-text="stockName" custom-api-value="id"
                          label="จากคลังสินค้า" show-code readonly
                          customApiEagerLoad
                        ></v-master-field>
                      </v-col>
                      <v-col cols="4">
                        <v-master-field v-model="currentStocksRequest.stockTo" 
                          :custom-api="['models','Stock','Stocks','query']"
                          custom-api-text="stockName" custom-api-value="id"
                          label="ไปยังคลังสินค้า" show-code readonly
                          customApiEagerLoad
                        ></v-master-field>
                      </v-col>
                      <v-col cols="12">
                        <v-enhance-table
                          :items="currentStocksRequest.requestData"
                          :headers="requestDataHeaders"
                        >
                          <template v-slot:item.productCode="props">
                            <span class="font-weight-bold">{{ props.item.productCode }}</span>
                          </template>
                          <template v-slot:item.productName="props">
                            <v-label-api :api-url="['models','Master','Products','find']" :api-key="props.item.productCode" api-field="productName"></v-label-api>
                          </template>
                          <template v-slot:item.quantity="props">
                            <span class="font-weight-bold">{{ props.item.quantity }}</span>
                          </template>
                        </v-enhance-table>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item key="dispensings">
              <v-model-table model-name="dispensings" model-title="รายการจ่ายของ"
                :model-api="['models','Stock','StocksDispensings']"
                :model-api-search="stockDispensingsQuery"
                api-with="product"
                api-order-by="created_at,desc"
                :headers="dispensingsHeaders"
                :initial-data="dispensingsInitialData"
                :dialog-width="($vuetify.breakpoint.mdAndUp) ? '50vw' : '80vw'"
                :insertable="!!currentStockId"
                ref="dispensingsTable"
              >
                <template v-slot:modelForm="{data,rules,isCreating}">
                  <v-container pa-0 fluid>
                    <v-row>
                      <v-col cols="8">
                        <v-master-field :rules="[rules.require()]" v-model="data.productCode" 
                          :custom-api="['models','Master','Products','query']"
                          custom-api-scope="availableAt"
                          :custom-api-scope-param="currentStockId"
                          custom-api-text="productName" 
                          custom-api-value="productCode" 
                          label="รายการ" show-code
                          customApiEagerLoad
                        ></v-master-field>
                      </v-col>
                      <v-col cols="2">
                        <v-master-field v-model="data.lotNo" 
                          :custom-api="['models','Stock','StocksProducts','query']"
                          :filterText="(data.productCode) ? {productCode: data.productCode, stockId: currentStockId} : undefined"
                          custom-api-text="lotNo" custom-api-value="lotNo"
                          waitForFilter waitForFilterText="กรุณาเลือกรายการสินค้า" customApiEagerLoad
                          label="Lot No." clearable
                        ></v-master-field>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field :rules="[rules.require(),rules.numeric()]" label="จำนวน" v-model="data.quantity"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </template>
                <template v-slot:toolBarItem="props">
                  <v-btn color="primary" @click="dispenseAll" class="ml-1">จ่ายของทั้งหมด</v-btn>
                  <v-spacer></v-spacer>
                </template>
                <template v-slot:toolBarButton="props" v-if="currentStockId">
                  <v-btn color="primary" @click="requestsToDispensings" class="ml-1">ดึงข้อมูลใบเบิกของ</v-btn>
                </template>
                <template v-slot:item.action="props">
                  <v-btn @click.stop="props.actions.editData(props.item)" class="ml-1" color="primary" v-if="props.item.status!='dispensed'">แก้ไข</v-btn>
                  <v-btn @click.stop="dispense(props.item,props.actions)" class="ml-1" color="primary" v-if="props.item.status!='dispensed'">จ่ายของ</v-btn>
                  <v-btn @click.stop="props.actions.deleteData(props.item)" color="error" class="ml-1" v-if="(props.item.status!='dispensed' || $permission('stock-undispensed'))"><v-icon class="mx-1">$vuetify.icons.delete</v-icon> ลบ</v-btn>
                </template>
              </v-model-table>
            </v-tab-item>
          </template>
        </v-tabs-group>
      </v-col>
      <v-col cols="12" v-else>
        <v-tabs-group>
          <template v-slot:tabs>
            <v-tab>ใบเบิกของรอจ่าย</v-tab>
            <v-tab>ใบเบิกของเรียบร้อย</v-tab>
          </template>
          <template v-slot:items>
            <v-tab-item>
              <v-model-table model-name="StocksRequests" model-title="ใบเบิกของรอจ่าย"
                :model-api="['models','Stock','StocksRequests']"
                :model-api-search="stocksRequestsQuery"
                api-order-by="requestDispensingDate,desc"
                :headers="headers"
                :insertable="false"
                ref="stocksRequestsTable"
              >
                <template v-slot:item.stockTo="props">
                  <v-label-api :api-url="['models','Stock','Stocks','find']" :api-key="props.item.stockTo" api-field="stockName"></v-label-api>
                </template>
                <template v-slot:item.stockFrom="props">
                  <v-label-api :api-url="['models','Stock','Stocks','find']" :api-key="props.item.stockFrom" api-field="stockName"></v-label-api>
                </template>
                <template v-slot:item.action="props">
                  <v-btn @click.stop="currentStocksRequest=props.item" color="primary"><v-icon class="mx-1">mdi-circle-edit-outline</v-icon> เปิด</v-btn>
                </template>
              </v-model-table>
            </v-tab-item>
            <v-tab-item>
              <v-model-table model-name="StocksRequests" model-title="ใบเบิกของเรียบร้อย"
                :model-api="['models','Stock','StocksRequests']"
                :model-api-search="stocksRequestsCompleteQuery"
                api-order-by="requestDispensingDate,desc"
                :headers="headers"
                :insertable="false" serverPagination
                ref="stocksRequestsCompleteTable"
              >
                <template v-slot:item.stockTo="props">
                  <v-label-api :api-url="['models','Stock','Stocks','find']" :api-key="props.item.stockTo" api-field="stockName"></v-label-api>
                </template>
                <template v-slot:item.stockFrom="props">
                  <v-label-api :api-url="['models','Stock','Stocks','find']" :api-key="props.item.stockFrom" api-field="stockName"></v-label-api>
                </template>
                <template v-slot:item.action="props">
                  <v-btn @click.stop="currentStocksRequest=props.item" color="primary"><v-icon class="mx-1">mdi-circle-edit-outline</v-icon> เปิด</v-btn>
                </template>
              </v-model-table>
            </v-tab-item>
          </template>
        </v-tabs-group>
      </v-col>
    </v-row>
    <v-row v-if="!currentStockId">
      <v-col cols="12">
        <v-card color="white">
          <v-card-text class="pa-0">
            <v-container py-2 fluid>
              <v-row dense>
                <v-col cols="11" align-self="center" class="text-left">
                  <label class="headline">Stock ID is not configed for this client. Please contact administrator.</label>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card> 
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import helperItem from '@/modules/helperItem'
import api from 'tantee-common/lib/api'

export default {
  data: ()=>({
    currentStocksRequest: undefined,
    currentStockId: undefined,
    headers: [
      {
        text: 'วันที่',
        value: 'created_at',
        class: 'body-2'
      },
      {
        text: 'วันที่ต้องการของ',
        value: 'requestDispensingDate',
        class: 'body-2'
      },
      {
        text: 'ขอมาที่',
        value: 'stockTo',
        class: 'body-2'
      },
      {
        text: 'ขอจาก',
        value: 'stockFrom',
        class: 'body-2'
      },
      {
        text: 'สถานะ',
        value: 'status',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '150px',
        sortable: false,
        class: 'body-2'
      }
    ],
    dispensingsHeaders: [
      {
        text: 'วัน-เวลา',
        value: 'created_at',
        class: 'body-2'
      },
      {
        text: 'รหัสสินค้า',
        value: 'productCode',
        class: 'body-2',
        width: '150px'
      },
      {
        text: 'รายการ',
        value: 'product.productName',
        class: 'body-2'
      },
      {
        text: 'Lot No.',
        value: 'lotNo',
        class: 'body-2'
      },
      {
        text: 'จำนวน',
        value: 'quantity',
        class: 'body-2'
      },
      {
        text: 'สถานะ',
        value: 'status',
        class: 'body-2'
      },
      {
        text: 'User',
        value: 'created_by',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '350px',
        sortable: false,
        class: 'body-2'
      }
    ],
    requestDataHeaders: [
      {
        text: 'รหัสสินค้า',
        value: 'productCode',
        class: 'body-2'
      },
      {
        text: 'ชื่อสินค้า',
        value: 'productName',
        class: 'body-2'
      },
      {
        text: 'จำนวน',
        value: 'quantity',
        class: 'body-2'
      },
    ],
  }),
  computed: {
    stockDispensingsQuery() {
      return {
        stocksRequestId: this.currentStocksRequest.id
      }
    },
    stocksRequestsQuery() {
      return {
        'stockFrom': this.currentStockId,
        'where#status': 'new'
      }
    },
    stocksRequestsCompleteQuery() {
      return {
        'stockFrom': this.currentStockId,
        'where#status#<>': 'new'
      }
    },
    dispensingsInitialData() {
      return {
        'stocksRequestId': this.currentStocksRequest.id,
        'stockFrom': this.currentStocksRequest.stockFrom,
        'stockTo': this.currentStocksRequest.stockTo
      }
    }
  },
  methods: {
    onRequestUnloaded() {
      this.currentStocksRequest = undefined
    },
    closeReject() {
      if (this.currentStocksRequest) {
        this.currentStocksRequest.status = 'reject'
        helperItem.updateItem(['models','Stock','StocksRequests'],this.currentStocksRequest).then((returnData)=>{
          this.onRequestUnloaded()
        })
      }
    },
    closeComplete() {
      if (this.currentStocksRequest) {
        this.currentStocksRequest.status = 'completed'
        helperItem.updateItem(['models','Stock','StocksRequests'],this.currentStocksRequest).then((returnData)=>{
          this.onRequestUnloaded()
        })
      }
    },
    dispenseAll() {
      let postData = {
        stocksRequestId: this.currentStocksRequest.id,
      }

      let dispensePromise = api.post(['Stock','StockController','dispenseStocksRequest'],postData).then(()=>{
        if (this.$refs.dispensingsTable) this.$refs.dispensingsTable.loadData()
      }).catch((e)=>{
        void e
      })

      this.$loading('Dispensing',dispensePromise)
    },
    dispense(item,action) {
      item.status = 'dispensed'
      action.saveData(item)
    },
    requestsToDispensings() {
      let postData = {
        stocksRequestId: this.currentStocksRequest.id,
      }
      let loadingPromise = api.post(['Stock','StockController','dispensingFromRequest'],postData).then(()=>{
        if (this.$refs.dispensingsTable) this.$refs.dispensingsTable.loadData()
      }).catch((e)=>{
        void e
      })

      this.$loading('Importing',loadingPromise)
    },
  },
  mounted() {
    let location = this.$store.getters.getConfig('location')
    if (location && location.stockId) this.currentStockId = location.stockId
  }
}
</script>

<style lang="css" scoped>
</style>
